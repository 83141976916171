<template src="./NomineeProcess.html">

</template>

<script>
import Nominee from "@/components/Nominee/Nominee";
import jobsService from "@/services/Jobs";

export default {
  name: "NomineeProcess",
  components: {
    Nominee
  },
  props: {
    nominee: {
      type: Object,
    }
  },
  created() {
    this.getSpecificJob();
  },
  methods: {
    async getSpecificJob() {
      try {
        this.jobOfferApplication = await jobsService.getSpecificJobsProcess(this.nominee.jobOfferId);
      } catch (e) {
        console.error('error', e);
      }
    },
  }
}
</script>

<style scoped lang="scss" src="./NomineeProcess.scss">

</style>
