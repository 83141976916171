import { render, staticRenderFns } from "./NomineeProcess.html?vue&type=template&id=217bc135&scoped=true&"
import script from "./NomineeProcess.vue?vue&type=script&lang=js&"
export * from "./NomineeProcess.vue?vue&type=script&lang=js&"
import style0 from "./NomineeProcess.scss?vue&type=style&index=0&id=217bc135&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "217bc135",
  null
  
)

export default component.exports